import * as utils from '../utils.js';

class Header {

  constructor(el) {

    //console.log('initHeader');

	  this.DOM = {};
	  this.DOM.el = el;
	  this.DOM.body = document.body;
	  this.DOM.HeaderContainer = document.querySelector('.Header-container');
	  this.DOM.brand = this.DOM.el.querySelector('.Header-logo');
    this.DOM.toggle = this.DOM.el.querySelector('.Menu-toggle');
    this.DOM.menuContainer = this.DOM.el.querySelector('.Menu-container');
    this.menuOpen = false;

    this.DOM.topBar = this.DOM.HeaderContainer.querySelector('.Topbar');
    this.DOM.topBarClose = this.DOM.topBar.querySelector('.Topbar-close');

	  this.init();

  }

  init() {

    //animation Open
    const menuOpenTL = new TimelineMax({ paused: true});

  	//toggle
  	this.DOM.toggle.addEventListener('click',(e) => {

  		e.preventDefault();
  		this.DOM.body.classList.toggle('showMenu');

      //if Openning
  		if ( this.DOM.body.classList.contains('showMenu') ) {

        this.menuOpen = true;
        this.DOM.body.style.overflow = "hidden";

  		} else {

        this.menuOpen = false;
        this.DOM.body.style.overflow = "auto";

  		}

  	});

    // Links Click
    const links = this.DOM.menuContainer.querySelectorAll('.Menu a')
    links.forEach((elem) => {

      elem.addEventListener('click',(e)=> {
        if ( this.DOM.menuContainer.querySelector('.active') != null ) {
           this.DOM.menuContainer.querySelector('.active').classList.remove('active');
        }
        e.currentTarget.parentNode.classList.add('active');

      });

    });

    // Brand Click
    this.DOM.brand.addEventListener('click',(e)=> {

      if ( this.DOM.menuContainer.querySelector('.active') != null ) {
         this.DOM.menuContainer.querySelector('.active').classList.remove('active');
      }

    })

    //Topbar
    this.DOM.topBarClose.addEventListener('click', (e) => {

      e.preventDefault();
      console.log(this.DOM.topBar.offsetHeight);
      TweenMax.to(document.querySelector('#barba-wrapper'), 1, {y:- this.DOM.topBar.offsetHeight, ease:Power3.easeOut, clearProps: 'all'});
      TweenMax.to(this.DOM.HeaderContainer, 1, {y:- this.DOM.topBar.offsetHeight, ease:Power3.easeOut, clearProps: 'all', onComplete:(e)=>{
        this.DOM.topBar.remove();
      }}, 0);

    })

  }

}

export default Header;
