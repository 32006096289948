import 'nodelist-foreach-polyfill'
import * as utils from './utils.js';
import Header from './modules/header.js';

import {initHome} from './pages/home.js';
import {initPage} from './pages/page.js';
import {initAppartment} from './pages/appartment.js';
import {initAppartments} from './pages/appartments.js';
import {initAbout} from './pages/about.js';
import {initHowitworks} from './pages/howitworks.js';
import {initProprios} from './pages/proprios.js';
import {initResa} from './pages/resa.js';
import {initProposer} from './pages/proposer.js';
import {initAccountResa} from './pages/account-resa.js';

class App {

  constructor(opt = {}) {

    //Declarations
    this.sy = 0;
    this.devMode = false;
    this.DOM = {};
    this.DOM.body = document.body;
    this.DOM.footer = document.querySelector('footer');
    this.DOM.barbaWrapper = document.querySelector('#barba-wrapper');

    //Signature Wokine
    window.scrollTo(0,0)
    console.log("%cCreated by Wokine, with ❤","color: #000; padding: 5px 0px 1px; border-bottom:2px solid #71d1c2;"),console.log("%chttp://wokine.com","color:#ccc")

    this.init();
    this.addEvents();
    this.intro();

  }

  init() {

    //DevMode ( infos navigateur )
    if (this.devMode) {
  		console.log('DEVMODE BIATCH');
    }

    //Resets
    this.DOM.body.scrollTop = document.documentElement.scrollTop = 0;
    history.scrollRestoration = 'manual';

    //popups
    jQuery('body').magnificPopup({
        delegate: '.Popup-link',
        type: 'ajax',
        removalDelay: 600,
        tLoading: 'Chargement...',
        mainClass: 'mfp-animate',
        closeBtnInside: true,
        closeMarkup:
            `<button title="%title%" type="button" class="mfp-close">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 29.41 29.41">
              <polygon points="29.41 1.41 28 0 14.71 13.29 1.41 0 0 1.41 13.29 14.71 0 28 1.41 29.41 14.71 16.12 28 29.41 29.41 28 16.12 14.71 29.41 1.41"/>
            </svg>
          </button>`,
        callbacks: {
          parseAjax: function (mfpResponse) {
              mfpResponse.data = jQuery(mfpResponse.data).find('.ajax-container');
          },
            ajaxContentAdded: function() {
                initProposer();

            }
        }
    });

    // Create Header
  	const header = new Header(document.querySelector('.Header'));

    //Barba
    Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container) => {

      var js = container.querySelectorAll("script");
       if(js != null){
          js.forEach((elem) => {
           eval(elem.innerHTML);
         });
       }

      // Animations
      if (!window.Sniff.features.mobile) {
        this.initPrllx(container);
        //this.initInView(container);
      }

      var aujourdhui = new Date();
    	var demain = new Date();
    	demain.setTime(demain.getTime() + 1000*3600*24);

    	var heure = aujourdhui.getHours();
    	if(heure<12){
    		var debut_datepicker = aujourdhui;
    		var fin_datepicker = demain;
    	}
    	else{
    		var apresdemain = new Date();
    		apresdemain.setTime(apresdemain.getTime() + 1000*3600*24*2);
    		var debut_datepicker = demain;
    		var fin_datepicker = apresdemain;
    	}

    	// Datepickers formulaire de recherche
      console.log(jQuery(container).find("#rech_from"));
    	jQuery(container).find("#rech_from").datepicker({
    		buttonImage: 'img/calendar.png',
    		buttonImageOnly: true,
    		buttonText: jQuery("#rech_from").attr('alt'),
    		minDate: debut_datepicker,
    		onSelect: function(){
    			jQuery('.placeholder').removeClass('placeholder');
    			initPlaceHolders();

    			if(jQuery("#rech_from").val()!="" && jQuery("#rech_from").val()!=jQuery("#rech_from").attr('placeholder')){
    				var nextday = new Date((jQuery("#rech_from").datepicker("getDate")).valueOf() + 1000*3600*24/**2*/); // désactivation des 2 nuits minimum
    				jQuery("#rech_to").datepicker("option", "minDate", nextday);
    			}
    		},
    		// onClose: function(){
    			//jQuery('#restrict_nb_nuits').hide(); // désactivation des 2 nuits minimum
    		// },
    		showOn: 'both'
    	});

      console.log(jQuery(container).find("#rech_to"));
    	jQuery(container).find("#rech_to").datepicker({
    		buttonImage: 'img/calendar.png',
    		buttonImageOnly: true,
    		buttonText: jQuery("#rech_to").attr('alt'),
    		minDate: fin_datepicker,
    		beforeShow: function(){
    			//jQuery('#restrict_nb_nuits').show(); // désactivation des 2 nuits minimum
    			if(jQuery("#rech_from").val()!="" && jQuery("#rech_from").val()!=jQuery("#rech_from").attr('placeholder')){
    				var nextday = new Date((jQuery("#rech_from").datepicker("getDate")).valueOf() + 1000*3600*24/**2*/); // désactivation des 2 nuits minimum
    				jQuery("#rech_to").datepicker("option", "minDate", nextday);
    			}
    		},
    		// onClose: function(){
    			//jQuery('#restrict_nb_nuits').hide(); // désactivation des 2 nuits minimum
    		// },
    		showOn: 'both'
    	});

      // Chosen
    	// LAISSER CET APPEL EN DERNIÈRE POSITION DE DOCUMENT.READY
    	jQuery('select.custom__select').chosen({ allow_single_deselect: true });
    	jQuery('.creer_compte select').chosen({ allow_single_deselect: true });
    	var zidx = 100;
    	jQuery('.chzn-container').each(function(){
    		jQuery(this).css('z-index', zidx);
    		zidx-=1;
    	});

      Shadowbox.init({
      	overlayOpacity:0.7
    	});

    });

    Barba.Dispatcher.on('linkClicked', (HTMLElement, MouseEvent) => {

      //linkClicked

    })

    Barba.Dispatcher.on('initStateChange', function() {

      //initStateChange
  		if (typeof ga === 'function') {
  			ga('send', 'pageview', location.pathname);
  		}

  	});

    var pageTransition = Barba.BaseTransition.extend({

  		start: function() {

  		  Promise.all([this.newContainerLoading, this.transitionOut()])
  		  .then(this.transitionIn.bind(this));

  		},
  		transitionOut: function() {

  			let deferred = Barba.Utils.deferred();
  			let oldContainer = this.oldContainer;

			  const pageOut = new TimelineMax({ paused:true, onComplete:()=>{

          //Close Menu if Open
          if(header.menuOpen){
            var click = new Event('click');
            header.DOM.toggle.dispatchEvent(click);
          }

          window.scrollTo(0,0);
				  deferred.resolve();

			  }});

        pageOut.to(oldContainer, 0.8, {opacity:0, ease:Power3.easeOut}, 0);
        pageOut.play();

  			return deferred.promise;

  		},
  		transitionIn: function() {

  			var _this = this;

  			this.oldContainer.style.display = "none";
  			this.newContainer.style.visibility = 'visible';

		    const pageIn = new TimelineMax({ paused:true, onComplete:()=>{
					_this.done();
				}})

        pageIn.from(this.newContainer, 1.2, {opacity:0, ease:Power3.easeOut}, 0);
        pageIn.play();

  		}

  	});

  	Barba.Pjax.getTransition = function() {
  	  return pageTransition;
  	};

  	//routes
  	initHome();
  	initPage();
    initAppartment();
    initAppartments();
    initAbout();
    initHowitworks();
    initResa();
    initProprios();
    initAccountResa();

    Barba.Pjax.start();

  }

  addEvents() {

    window.addEventListener('scroll', (e) => {
  		this.sy = window.pageYOffset || document.documentElement.scrollTop;
  	})

  }

  initPrllx(container) {

	  const TLPrllxs = []
	  const prllxs = container.querySelectorAll('*[data-prllx]');
	  prllxs.forEach((item) => {

		    let prllxTL = new TimelineMax({ paused: true});
        if (!item.getAttribute('data-prllxSimple')) TweenMax.set(item, { y: -1 * item.getAttribute('data-prllx') });
  	    prllxTL.to(item, 1, { y:item.getAttribute('data-prllx'), overwrite:"all", ease:Power0.easeNone });
		    TLPrllxs.push(prllxTL);

	  });

	  var prllxRender = function (){

	    prllxs.forEach((item, index) => {
        let from = item.getBoundingClientRect().top + this.sy - window.innerHeight ;
        if ( from < 0 ) from = 0;
	      let norm = utils.clamp(utils.normalize(this.sy,  from ,  item.getBoundingClientRect().top + this.sy + item.offsetHeight), 0, 1);
		    TLPrllxs[index].progress(norm);
	    });

	  }

	  TweenMax.ticker.addEventListener("tick", prllxRender, this, true, 1);

  }

  initInView(container) {


    //fadeIn
    const fadeInElems = container.querySelectorAll('.fadeInView');

    if (fadeInElems) {
      fadeInElems.forEach((elem) => {
        elem.fadeInTL = new TimelineMax({ paused: true});
        elem.fadeInTL.from(elem, 1, {y:80, opacity:0, ease:Power3.easeOut}, 0);
      })

      inView("main .fadeInView").on('enter', el => {
        if(!el.done)  el.fadeInTL.duration(animationDuration).play();
      }).on('exit', el => {
        el.done = true;
        //el.fadeInTL.duration(0.2).reverse();
      });

    }

    //letterIn
    const lettersInElems = container.querySelectorAll('.lettersInView');
    if (lettersInElems) {

      lettersInElems.forEach((elem) => {
        elem.splitLetters = new SplitText(elem, {type:"chars"});
        elem.lettersInTL = new TimelineMax({ paused: true});
        elem.lettersInTL.staggerFrom(elem.splitLetters.chars, 1, {y:20, opacity:0, ease:Power3.easeOut}, 0.015, 0);
      })

      inView("main .lettersInView").on('enter', el => {
        if(!el.done)  el.lettersInTL.duration(animationDuration).play();
      }).on('exit', el => {
        el.done = true;
        el.innerHTML = el.splitLetters._originals;
        //el.lettersInTL.duration(0.2).reverse();
      });

    }

    //linesIn
    const linesInElems = container.querySelectorAll('.linesInView');
    if (linesInElems) {

      linesInElems.forEach((elem) => {
        elem.splitLines = new SplitText(elem, {type:"lines"});
        elem.linesInTL = new TimelineMax({ paused: true});
        elem.linesInTL.staggerFrom(elem.splitLines.lines, 1, {y:20, opacity:0, ease:Power3.easeOut}, 0.05, 0.2);
      })

      inView("main .linesInView").on('enter', el => {
        if(!el.done)  el.linesInTL.duration(animationDuration * 1.5).play();
      }).on('exit', el => {
        el.done = true;
        el.innerHTML = el.splitLines._originals;
        //el.linesInTL.duration(0.2).reverse();
      });

    }

    inView.offset({
        top: -120,
        left:-50,
        right:-50,
        bottom: 0,
    });

  }

  intro() {

    const intro = new TimelineMax({ paused:true,
      onStart:()=>{
        this.DOM.body.classList.remove('is-loading');
      },
      onComplete: () => {
        if (document.getElementById('covid-popup')) {
          jQuery.magnificPopup.open({
            items: { src: '#covid-popup' }, type: 'inline'
          });
        }
      }
    });

    intro.from(this.DOM.body, 1.2, {opacity:0, ease:Power3.easeOut, clearProps:"opacity"}, 0.3)
    intro.play();

  }

}

const app = new App();
