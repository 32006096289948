export function initAbout() {

	var Page = Barba.BaseView.extend({

		namespace: 'page-about',

		onEnter: function() {

			//console.log('Init About');

			document.querySelector('body').classList.add('Header--light');
			document.querySelector('body').classList.add('Header--absolute');

		},

		onLeave: function() {

			document.querySelector('body').classList.remove('Header--light');
			document.querySelector('body').classList.remove('Header--absolute');

		}

	})

	Page.init();

}
