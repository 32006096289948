export function initHome() {

	var Home = Barba.BaseView.extend({

		namespace: 'page-home',

		onEnter: function() {

			//console.log('Init Home');
			document.querySelector('body').classList.add('Header--light');
			document.querySelector('body').classList.add('Header--absolute');

			var swiperHero = new Swiper('.Hero .swiper-container', {
				loop:true,
				speed : 600,
				effect: 'fade',
				autoplay: {
			    delay: 5000,
			    disableOnInteraction: false,
			  }
			});

		},

		onLeave: function() {

			document.querySelector('body').classList.remove('Header--light');
			document.querySelector('body').classList.remove('Header--absolute');

		}

	})

	Home.init();

}
