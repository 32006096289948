export function initAccountResa() {

	var Page = Barba.BaseView.extend({

		namespace: 'page-account-resa',

		onEnter: function() {

			//console.log('Init Compte Reservation');

			const Appartments = this.container.querySelectorAll('.Appartment');
			if (Appartments) {
      Appartments.forEach((item) => {
				item.querySelector('.detail_loc .detail__toggle').addEventListener('click', (e)=> {
					e.preventDefault();
					e.currentTarget.classList.toggle('active');
					//console.log(e.currentTarget.classList.contains('active'));
					if (e.currentTarget.classList.contains('active')){
						TweenMax.to(item.querySelector('.detail_loc > .inner'), 0.8, {height: item.querySelector('.detail_loc .inner ul').offsetHeight, ease:Power3.easeOut });
					} else {
						TweenMax.to(item.querySelector('.detail_loc > .inner'), 0.6, {height: 0, ease:Power3.easeOut });
					}
				})
			});

		}

	}
})

	Page.init();

}
