export function initResa() {

	var Page = Barba.BaseView.extend({

		namespace: 'page-reservation',

		onEnter: function() {

			var aujourdhui = new Date();
    	var demain = new Date();
    	demain.setTime(demain.getTime() + 1000*3600*24);

    	var heure = aujourdhui.getHours();
    	if(heure<12){
    		var debut_datepicker = aujourdhui;
    		var fin_datepicker = demain;
    	}
    	else{
    		var apresdemain = new Date();
    		apresdemain.setTime(apresdemain.getTime() + 1000*3600*24*2);
    		var debut_datepicker = demain;
    		var fin_datepicker = apresdemain;
    	}

			// Datepickers réservation étape 1
			jQuery("#rech_from_resa").datepicker({
				buttonImage: 'img/calendar.png',
				buttonImageOnly: true,
				buttonText: jQuery("#rech_from_resa").attr('alt'),
				minDate: debut_datepicker,
				onSelect: function(){
					jQuery('.placeholder').removeClass('placeholder');
					initPlaceHolders();

					if(jQuery("#rech_from_resa").val()!="" && jQuery("#rech_from_resa").val()!=jQuery("#rech_from_resa").attr('placeholder')){
						var nextday = new Date((jQuery("#rech_from_resa").datepicker("getDate")).valueOf() + 1000*3600*24/**2*/); // désactivation des 2 nuits minimum
						jQuery("#rech_to_resa").datepicker("option", "minDate", nextday);
					}

					modif_resa('dates', '', jQuery("#langue").val());
				},
				showOn: 'both'
			});
			jQuery("#rech_to_resa").datepicker({
				buttonImage: 'img/calendar.png',
				buttonImageOnly: true,
				buttonText: jQuery("#rech_to_resa").attr('alt'),
				minDate: fin_datepicker,
				beforeShow: function(){
					if(jQuery("#rech_from_resa").val()!="" && jQuery("#rech_from_resa").val()!=jQuery("#rech_from_resa").attr('placeholder')){
						var nextday = new Date((jQuery("#rech_from_resa").datepicker("getDate")).valueOf() + 1000*3600*24/**2*/); // désactivation des 2 nuits minimum
						jQuery("#rech_to_resa").datepicker("option", "minDate", nextday);
					}
				},
				onSelect: function(){
					modif_resa('dates', '', jQuery("#langue").val());
				},
				showOn: 'both'
			});

			jQuery('.qte').on('change', function(e){
				if ( jQuery(e.currentTarget).val() != "" && jQuery(e.currentTarget).val() > 0 ) {
					jQuery(e.currentTarget).addClass('filled');
				} else {
					jQuery(e.currentTarget).removeClass('filled');
				}
			})

		},

		onLeaveCompleted: function() {



		}

	})

	Page.init();

}
