export function initAppartments() {

    var Page = Barba.BaseView.extend({

        namespace: 'appartements',

        onEnterCompleted: function () {


            var lang = jQuery("html").attr("lang");

            console.log('Init Appartments');
            /* CHECKBOX QUARTIER */
        		jQuery('#quartier input[type=checkbox]').change(function(){
        			var liste_quartier = '';
        			jQuery("#quartier input:checkbox:checked").each(function(){
        				liste_quartier = liste_quartier.concat(jQuery(this).val()+'-');

        	        });
        					console.log(liste_quartier == "" ? "show" : "hide");
        	        refresh_liste_appart('type=quartier&quartier='+liste_quartier+'&langue='+lang);
        		});

        	    /* CHECKBOX TYPE */
        	    jQuery('#type_bien input[type=checkbox]').change(function(){
        	        var liste_typebien = '';
        	        jQuery("#type_bien input:checkbox:checked").each(function(){
        	            liste_typebien = liste_typebien.concat(jQuery(this).val()+'-');
        	        });

        	        refresh_liste_appart('type=typebien&typebien='+liste_typebien+'&langue='+lang);
        	    });

        		/* CHECKBOX EQUIPEMENTS */
        		jQuery('#equipements input[type=checkbox]').change(function(){
        			var liste_equipements = '';
        			jQuery("#equipements input:checkbox:checked").each(function(){
        				liste_equipements = liste_equipements.concat(jQuery(this).val()+'-');
        			});
        			refresh_liste_appart('type=equipements&equipements='+liste_equipements+'&langue='+lang);
        		});


        		var page= parseInt(jQuery(".Appartment-preview").length/12);
            jQuery("#showMore").click(function (e) {
        	        e.preventDefault();
					console.log(this);
        	        var _this = jQuery(this);
        	        page++;
        	        jQuery.ajax({
        	            url: "rechercher-location-appartements-meubles-lille-" + lang + "-page-"+page+".html",
        	            type: "GET",
        	            success: function (data) {
        	                var dataDoc = jQuery(data);
        	                var part = dataDoc.find(".Appartments-list").html();
        	                jQuery(".Appartments-list").append(part);
        	                if (dataDoc.find(".Appartment-preview").length < 12) jQuery("#showMore").hide();
        	                else jQuery("#showMore").show();
        	            }
        	        })

        	    });

        	    jQuery("input[type=checkbox]").first().change();

        },

        onLeaveCompleted: function () {


        }

    })
    Page.init();

}
