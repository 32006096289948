export function initAppartment() {

    var Page = Barba.BaseView.extend({

        namespace: 'single-appartement',

        onEnter: function () {

            console.log('Init Appartment');


            //fb
            jQuery(".partage").click(function (e) {
                e.preventDefault();
                window.open(
                    jQuery(this).attr('href')+encodeURI(window.location.href),
                    'fbShareWindow',
                    'height=450, width=550, top=' + (jQuery(window).height() / 2 - 275) + ', left=' + (jQuery(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
                );
            })

            //Anchors
            const AnchorsLinks = this.container.querySelectorAll('.anchors');
            AnchorsLinks.forEach((item) => {
                item.addEventListener('click', (e) => {
                    e.preventDefault();

                    //anchors active
                    let targetAnchor = e.currentTarget.getAttribute("href");
                    e.currentTarget.classList.add('is-active');
                    TweenMax.to(window, 1.2, {scrollTo: {y: targetAnchor, autoKill: false}, ease: Power3.easeInOut});
                })
            })

            var swiperAppartment = new Swiper('.Appartment-swiper', {
                virtualTranslate: true,
                navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                },
            });

            const Map = this.container.querySelector("#gmap")
            var lat = Map.dataset.lat;
            var lng = Map.dataset.lng;
            var myLatlng = new google.maps.LatLng(lat, lng);
            var mapStyles = [
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dadada"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#c9c9c9"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                }
            ]
            var myOptions = {
                zoom: 14,
                center: myLatlng,
                //mapTypeControl: true,
                styles: mapStyles,
                disableDefaultUI: true,
                /*mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
                },
                zoomControl: true,
                zoomControlOptions: {
                        style: google.maps.ZoomControlStyle.SMALL
                },*/
                mapTypeId: google.maps.MapTypeId.ROADMAP
            }
            var gmap = new google.maps.Map(Map, myOptions);
            var imageMarqueur = new google.maps.MarkerImage('build/img/shared/marker.png', new google.maps.Size(30, 40), new google.maps.Point(0, 0), new google.maps.Point(15, 40), new google.maps.Size(30, 40));
            var marqueur = new google.maps.Marker({
                position: myLatlng,
                map: gmap,
                icon: imageMarqueur
            });

            var Avis = this.container.querySelectorAll(".avis__container .avis");
            Avis.forEach((elem, index) => {
                if (index < 2) elem.classList.remove('hidden');
            });

            this.container.querySelector("#showMore").addEventListener('click', (e) => {
                e.preventDefault();
                e.currentTarget.classList.add('hidden');
                Avis.forEach((elem, index) => {
                    if (index > 1) elem.classList.toggle('hidden');
                });
            });

            const videoDOm = this.container.querySelector(".Appartment-video video");
            console.log(videoDOm);
            if (videoDOm) {
              const videoPlayer = new Plyr(videoDOm);
            }

            const dispoCalendar = this.container.querySelector("#dispoCalendar");
            const indisponibilities = JSON.parse(dispoCalendar.dataset.indisponibilities)


            if (dispoCalendar) {
                const calendar = new FullCalendar.Calendar(dispoCalendar, {
                    locale: 'fr',
                    timeZone: 'Europe/Paris',
                    views: {
                        dayGridMonth: { // name of view
                            titleFormat: { year: 'numeric', month: 'long' }
                        }
                    },
                    dayCellClassNames: function(data){
                        const Ymd = data.date.toISOString().split('T')[0];
                        if (Object.values(indisponibilities).includes(Ymd)) return 'disabled';
                    }
                });
                calendar.render();
            }

        },

        onLeaveCompleted: function () {


        }

    })

    Page.init();

}
