export function initProprios() {

	var Page = Barba.BaseView.extend({

		namespace: 'page-proprios',

		onEnter: function() {

			//console.log('Init Propios');

			const FAQList = this.container.querySelector('.FAQ-list');
			const FAQitems = this.container.querySelectorAll('.FAQ-item');
			FAQitems.forEach((item) => {

				item.querySelector('.question').addEventListener('click', (e)=> {

					e.preventDefault();

					let QuestionLink = e.currentTarget;
					let Question = QuestionLink.parentNode;
					let ReponseContainer = Question.querySelector('.question-collapse');
					let Reponse = Question.querySelector('.inner');

					if (FAQList.querySelector('.active')) {
						TweenMax.to(FAQList.querySelector('.active .question-collapse'), 0.8, {height:0, ease:Power3.easeOut}, 0);
						FAQList.querySelector('.active').classList.remove('active');
					}

					Question.classList.add('active');
					TweenMax.fromTo(ReponseContainer, 0.8, {height:0}, { height:Reponse.offsetHeight, ease:Power3.easeOut}, 0);


				});

			});

			window.addEventListener('resize', function(){
				if (FAQList.querySelector('.active')) {
					TweenMax.set(FAQList.querySelector('.active .question-collapse'), {height:0}, 0);
				} else {
					return;
				}
			})

		},

		onLeave: function() {

			document.querySelector('body').classList.remove('Header--absolute');

		}

	})

	Page.init();

}
