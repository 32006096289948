export function initProposer() {

	//console.log('init Proposer');

	jQuery("section.formulaire").show();
	var formProposer = jQuery("#form_proposer_bien");
	formProposer.on("submit",function (e) {
		e.preventDefault();
		jQuery.ajax({
			"type": "POST",
			"url" : formProposer.data("ajaxurl"),
			"data" : {
				send: "ok",
				type_bien_detail: jQuery("input[name=type_bien_detail]:checked").val(),
				ville: jQuery("#ville").val(),
				description: jQuery("#description").val(),
				nom: jQuery("#name").val(),
				prenom: jQuery("#prenom").val(),
				email: jQuery("#mail").val(),
				tel_fixe: jQuery("#phone").val(),
				tel_portable: jQuery("#phone_2").val(),
			},
			"success": function (data) {
				window.location.href = data;
			},
			"error": function (xhr) {
				var responseDom = jQuery(xhr.responseText);
				var formHtml = responseDom.find("#form_proposer_bien").html()
				formProposer.html(formHtml);
			}
		})
	})

}
